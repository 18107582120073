import React, { useState } from "react"
import styled from 'styled-components'
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css'
import media from '../styles/media'
import MarkImg from '../images/marker.webp'

const markerStyles = {
  display: "flex",
  alignItems: "center",
  height: "20px",
  fontSize: "20px",
  width: "20px",
  borderRadius: "50%",
  cursor: "pointer",
  backgroundImage: `url(${MarkImg})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  zIndex: 9
};

const ScrollNext = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1.2fr 2fr 0.5fr;

  .map {
    grid-column: 1/2;
    grid-row: 2/3;
    height: 400px;
    .overlay {
      display: block;
      position: absolute;
      width: 100vw;
      height: 400px;
      z-index: 8;
    }
    .mapMark {
      z-index: 9;
    }
  }

  .contact {
    grid-column: 1/2;
    grid-row: 1/2;
    margin: 0 5vw;
    .social  {
      margin-bottom: 40px;
    }
    p {
      margin-bottom: 1em;
    }
    a {
      font-size: 16px;
      line-height: 18px;
      color: #404040;
      transition: all .3s ease;
      &:hover {
        color: #64BF60;
      }
    }
  }

  .copyright {
    grid-column: 1/2;
    grid-row: 3/4;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    p {
      text-align: center;
      margin-top:  40px;
    }
  }

  .mapboxgl-ctrl {
    display: none !important;
  }

  ${media.laptop`
    height: 100vh;
    display: block;
    width:  400px;
    margin-left: 400px;
    overflow: hidden;
    .map {
      margin: 5vh 0 7vh;
      height: 250px;
      display: flex;
      overflow: hidden;
      justify-content: flex-end;
      .overlay {
        display: none;
      }
    }
    .mapMark{
      width: 20px;
      height: 20px;
      background-color:  #64BF60;
      border-radius: 50%;
    }
    .contact {
      margin-left: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      p {
        margin-bottom: 1em;
        &.heading {
          font-family: 'Merriweather', serif;
           
          font-size: 16px;
          line-height: 40px;
          color: black;
        }
      }
      a {
        font-size: 16px;
        line-height: 18px;
        color: #404040;
        transition: all .3s ease;
        &:hover {
          color: #64BF60;
        }
      }
    }
    .copyright {
      text-transform: uppercase;
      color: #404040;
      font-size: 12px;
      line-height: 14px;
      position: relative;
      display: flex;
      justify-content: flex-end;
      margin-right: 5vw;
      margin-top: 4vh;
    }
  `}

  ${media.laptopL`
    width:  580px;
    .map {
      margin: 5vh 0 7vh;
      height: 400px;
      display: flex;
      overflow: hidden;
      justify-content: flex-end;
      .overlay {
        display: none;
      }
    }
  `}
`

const Next = () => {
  const [viewport] = useState({
    latitude: -27.5,
    longitude: 29.5,
    center: [29, -27.8],
    zoom: [5.5]
  });

  const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiYXNocmVmcmVzaCIsImEiOiJja2ttYXVwcTkxcTViMm9wZzVkOXk1aWxxIn0.EWPNJaFNn4hrLii5O8b7Fg'
});

  return (
    <ScrollNext>
      <div className="map">
        <div className="overlay"></div>
        <Map
          style="mapbox://styles/mapbox/streets-v9"
          center={viewport.center}
          zoom={viewport.zoom}
          containerStyle={{
            height: '400px',
            width: '100%'
          }}
        >
          <Marker
            style={markerStyles}
            coordinates={[30.229702789709125, -29.767941555279984]}
            anchor="bottom"
            onClick={() => {
              window.open('https://www.google.co.za/maps/place/ServWorx/@-29.6298753,30.3657314,17z/data=!3m1!4b1!4m5!3m4!1s0x1ef6bce01fd813ff:0x1bdcb53610320a78!8m2!3d-29.62988!4d30.3679201', '_blank')
            }}
          >
            <div className="marker-content">&nbsp;&nbsp;&nbsp;&nbsp;</div>
          </Marker>
          <Marker
            style={markerStyles}
            coordinates={[28.444394956592123, -26.056456228204488]}
            anchor="bottom"
            onClick={() => {
              window.open('https://www.google.co.za/maps/place/Servworx+Germiston+Depot/@-26.144708,28.1841817,17z/data=!3m1!4b1!4m5!3m4!1s0x1e951394f1c5bf77:0x4ecc85470690210c!8m2!3d-26.1447128!4d28.1863704', 'blank')
            }}
          >
            <div className="marker-content">&nbsp;&nbsp;&nbsp;&nbsp;</div>
          </Marker>
        </Map>
      </div>
      <div className="contact">
        <div className="social">
          <p className="heading">Follow</p>
          <p><a href="https://www.facebook.com/ServWorx" rel="noreferrer" target="_blank">Facebook</a></p>
          <p><a href="https://www.linkedin.com/company/servworxsa/" rel="noreferrer" target="_blank">LinkedIn</a></p>
        </div>
        <div>
          <p className="heading">Contact</p>
          <p><a href="tel:0333869281">033 386 9281</a></p>
          <p><a href="mailto: sales@servworx.co.za">sales@servworx.co.za</a></p>
        </div>
      </div>
      <div className="copyright"><p>ServWorx © 2021</p></div>
    </ScrollNext>
  )
}

export default Next