import React from "react"
import styled from 'styled-components'

const MobNextCon = styled.div`
  width: 100vw;
  height: auto;
  background-color: #404040;
  color: white;
  padding: 5em 6.2vw 4em;
  p {
    margin-bottom: 1em;
    &.heading {
      font-family: 'Merriweather', serif;
       
      font-size: 15px;
      line-height: 19px;
    }
  }
  a {
    font-size: 14px;
    line-height: 16px;
    color: #BDBDBD;
  }
  .contact {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3em;
  }
  .copyright {
    color: #bdbdbd;
    font-size: 10px;
    line-height: 11px;
    margin-top: 8em;
    text-transform: uppercase;
  }
`

const MobNext = ({ containerWidth }) => {

  return (
    <MobNextCon>
      <div className="contact">
        <div className="social">
          <p className="heading">Follow</p>
          <p><a href="https://www.facebook.com/ServWorx" rel="noreferrer" target="_blank">Facebook</a></p>
          <p><a href="https://www.linkedin.com/company/servworxsa/" rel="noreferrer" target="_blank">LinkedIn</a></p>
        </div>
        <div>
          <p className="heading">Contact</p>
          <p><a href="tel:0333869281">033 386 9281</a></p>
          <p><a href="mailto: sales@servworx.co.za">sales@servworx.co.za</a></p>
        </div>
      </div>
      <div className="address">
        <p className="heading">Visit</p>
        <p>
          <a href="https://goo.gl/maps/TqGoEn62dfMXLGyt9" rel="noreferrer" target="_blank"> 
            21 Barnsley Road, Camps Drift<br/>
            Pietermaritzburg, 2000<br/>
          </a>
        </p>
      </div>
      <div className="copyright"><p>ServWorx © 2021</p></div>
    </MobNextCon>
  )
}

export default MobNext